<template>
    <ContainerComponent direction="vertical">
        <HeaderComponent height="44px">
            <RowComponent justify="center" align="center">
                <BreadcrumbComponent :default-active="$route.path">
                    <BreadcrumbItemComponent :to="`/survey/${$route.params.id}/create`"
                        >Create</BreadcrumbItemComponent
                    >
                    <transition name="highlight">
                        <BreadcrumbItemComponent
                            v-if="survey.logicEnabled"
                            :to="`/survey/${$route.params.id}/logic`"
                            >Logic</BreadcrumbItemComponent
                        >
                    </transition>
                    <BreadcrumbItemComponent :to="`/survey/${$route.params.id}/design`"
                        >Design</BreadcrumbItemComponent
                    >
                    <BreadcrumbItemComponent :to="`/survey/${$route.params.id}/configure`"
                        >Configure</BreadcrumbItemComponent
                    >
                    <BreadcrumbItemComponent :to="`/survey/${$route.params.id}/share`"
                        >Share</BreadcrumbItemComponent
                    >
                </BreadcrumbComponent>
            </RowComponent>
        </HeaderComponent>
        <ContainerComponent id="container">
            <AsideComponent width="21%">
                <slot name="navigation" />
            </AsideComponent>
            <MainComponent>
                <slot />
            </MainComponent>
            <AsideComponent v-if="$slots.controls" width="21%">
                <slot name="controls" />
            </AsideComponent>
        </ContainerComponent>
    </ContainerComponent>
</template>

<script>
import { db } from '@/firebase'

const surveys = db.ref('v2/survey_meta/')

export default {
    name: 'AppLayout',

    data() {
        return {
            survey: {
                logicEnabled: false
            }
        }
    },

    watch: {
        '$route.params.id': {
            handler(id) {
                this.$rtdbBind('survey', surveys.child(id))
            }
        }
    },

    async mounted() {
        await this.$store.dispatch('auth/getUser')
        await this.$store.dispatch('auth/getPermissions')
        await this.$store.dispatch('survey/getSurvey', { id: this.$route.params.id })
    }
}
</script>

<style lang="scss">
.container-component {
    min-width: 1024px;
}

.container-component,
.menu-component {
    position: relative;

    height: 100vh;
}

.header-component {
    z-index: z-index('fixed');

    padding: 0;

    background-color: #579ae6;
    box-shadow: rgb(0 0 0 / 4%) 0 0 8px, rgb(0 0 0 / 10%) 0 0 4px;

    .row-component {
        width: 100%;
    }

    .breadcrumb-component {
        max-width: 800px;
    }

    .highlight-enter-active,
    .highlight-enter-active:before {
        animation: highlight 2s;
    }

    @keyframes highlight {
        0%,
        50%,
        100% {
            background-color: #5fa9ff;
        }
        25%,
        75% {
            background-color: #2ecc71;
        }
    }
}

.aside-component {
    position: relative;

    z-index: z-index('above');

    min-width: 345px;
    max-width: 430px;

    height: calc(100vh - 44px);

    background-color: #fff;

    .menu-item-component {
        color: #34495e;

        font-size: 14px;
        font-weight: 400;

        height: 56px;

        padding-left: 8px;

        &:hover,
        &.is-active {
            background-color: darken(#fafafa, 1);

            opacity: 1 !important;
        }

        &.is-active {
            color: #34495e;

            font-weight: 500;
        }

        &:after {
            content: '';

            position: absolute;

            bottom: 0;
            left: 48px;
            right: 0;

            height: 1px;

            background-color: #f3f3f3;
        }

        svg {
            margin-right: 8px;
        }
    }
}

.main-component {
    position: relative;

    z-index: z-index('above');

    height: 100vh;

    overflow-y: auto;

    > div {
        padding: 20px 0 80px;
    }

    .card-component {
        margin-bottom: 32px;
    }
}
</style>
