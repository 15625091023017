<template>
    <AppLayout>
        <template #navigation>
            <MenuComponent :default-active="$route.path" direction="vertical" router>
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure`">
                    <svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="1.414"
                    >
                        <path
                            d="M26.178 25.489h25.284a.814.814 0 010 1.626H26.178V25.49z"
                            fill="#888"
                            fill-opacity=".498"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M12.538 25.489h11.965v1.626H12.538a.814.814 0 010-1.626z"
                            fill="#ffb856"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M35.967 14.093h15.495a.814.814 0 010 1.626H35.967v-1.626z"
                            fill="#888"
                            fill-opacity=".498"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M12.538 14.093h21.877v1.626H12.538a.814.814 0 010-1.626z"
                            fill="#85dcfd"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M41.374 36.885h10.088a.814.814 0 010 1.626H41.374v-1.626z"
                            fill="#888"
                            fill-opacity=".498"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M12.538 36.885h27.284v1.626H12.538a.814.814 0 010-1.626z"
                            fill="#ff788d"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M22.273 48.28h29.19a.814.814 0 010 1.627h-29.19v-1.626z"
                            fill="#888"
                            fill-opacity=".498"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M12.538 48.28h8.62v1.627h-8.62a.814.814 0 010-1.626z"
                            fill="#90e8c9"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M40.714 33.85a3.91 3.91 0 013.906 3.906 3.91 3.91 0 01-3.906 3.905 3.91 3.91 0 01-3.905-3.905 3.91 3.91 0 013.905-3.906zM21.972 45.19a3.91 3.91 0 013.905 3.905A3.91 3.91 0 0121.972 53a3.91 3.91 0 01-3.906-3.905 3.91 3.91 0 013.906-3.905zM35.229 11a3.91 3.91 0 013.905 3.906 3.91 3.91 0 01-3.905 3.905 3.91 3.91 0 01-3.906-3.905A3.91 3.91 0 0135.229 11zm-9.052 11.425a3.91 3.91 0 013.907 3.905 3.91 3.91 0 01-3.907 3.906 3.91 3.91 0 01-3.904-3.906 3.91 3.91 0 013.904-3.905z"
                            fill="#888"
                            fill-rule="nonzero"
                        />
                    </svg>
                    <span>General settings</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure/notifications`"
                    ><svg
                        preserveAspectRatio="xMinYMid"
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                    >
                        <path
                            fill="#F9C82B"
                            d="M53.866 43.88l-16.589 6.859c.015.042.037.078.05.12a4.771 4.771 0 01-3.202 5.935 4.756 4.756 0 01-5.615-2.429l-3.601 1.488a2.003 2.003 0 01-2.612-1.084 2.006 2.006 0 011.084-2.612l.613-.253-3.85-15.897-.574-1.387c-2.068-5-.647-10.562 3.134-14.009-1.214-.763-2.183-1.931-2.625-3.413A6.136 6.136 0 0124.2 9.562a6.13 6.13 0 017.632 4.118 6.093 6.093 0 01-.063 3.687c4.665.245 8.998 3.098 10.902 7.702l.573 1.386 8.502 13.974.592-.244a2.004 2.004 0 012.612 1.084 2.004 2.004 0 01-1.084 2.611zm-28.63-30.852a2.515 2.515 0 101.441 4.82 2.515 2.515 0 00-1.441-4.82zm13.389 15.336a2.005 2.005 0 00-2.613-1.084 2.006 2.006 0 00-1.084 2.612l4.586 11.09a2.007 2.007 0 002.612 1.084 2.004 2.004 0 001.084-2.613l-4.585-11.089z"
                        /></svg
                    ><span>Notifications</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure/kiosk`"
                    ><svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="1.414"
                    >
                        <path
                            d="M33.472 41.494a1.473 1.473 0 00-2.944 0v13.013a1.473 1.473 0 002.944 0V41.494z"
                            fill="#7d8c8e"
                        />
                        <path fill="#ffcc01" d="M16.663 21h30.674v22H16.663z" />
                        <path
                            d="M17.608 42.785V21.213h28.687v21.572H17.608zm30.545 1.427h.034c.726 0 1.313-.586 1.313-1.312l-.002-.033h.002V21.134h-.002l.002-.033c0-.727-.588-1.313-1.313-1.313-.012 0-.022.002-.034.003v-.003H15.845v.035c-.012 0-.023-.003-.035-.003a1.31 1.31 0 00-1.31 1.311l.002.034H14.5v21.672h.002l-.002.035c0 .725.586 1.31 1.31 1.31l.035-.002v.034l32.308-.002z"
                            fill="#444"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M15.973 31.145a.853.853 0 11-.002 1.706.853.853 0 01.002-1.706"
                            fill="#fff"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M49.5 20.1v13.047c-6.623 0-12.022-5.81-12.206-13.046H49.5z"
                            fill="#34495e"
                            fill-opacity=".2"
                        />
                        <g transform="translate(31.797 3) scale(.53442)">
                            <path
                                d="M19.245 14.552a2.407 2.407 0 00-3.403.001L13.1 17.295a2.407 2.407 0 103.403 3.404l2.742-2.743c.94-.94.94-2.464 0-3.404z"
                                fill="#1993e9"
                            />
                            <path fill="#858990" d="M29.064 8.084h5.872v5.614h-5.872z" />
                            <path
                                d="M32 31.652l9.346-9.278"
                                fill="none"
                                stroke="#444"
                                stroke-width="2"
                            />
                            <circle
                                cx="32"
                                cy="32"
                                r="19.152"
                                fill="#fff"
                                stroke="#858990"
                                stroke-width="2"
                                stroke-miterlimit="10"
                            />
                            <circle
                                cx="32"
                                cy="32"
                                r="19.152"
                                fill="#ff7690"
                                transform="translate(26.988 26.988) scale(.15664)"
                            />
                            <path
                                d="M40.74 22.374l-8.761 9.651"
                                fill="none"
                                stroke="#444"
                                stroke-width="2"
                                stroke-miterlimit="10"
                            />
                            <path
                                d="M40.346 6.558a2.407 2.407 0 00-2.407-2.406H26.061a2.407 2.407 0 100 4.813h11.878a2.407 2.407 0 002.407-2.407z"
                                fill="#444"
                            />
                        </g></svg
                    ><span>Kiosk mode</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure/integrations`"
                    ><svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-linejoin="round"
                        stroke-miterlimit="1.414"
                    >
                        <path
                            d="M52 47.21c0 2.64-2.14 4.787-4.779 4.787H16.78A4.78 4.78 0 0112 47.21V16.783a4.78 4.78 0 014.78-4.78h30.44a4.78 4.78 0 014.78 4.78V47.21z"
                            fill="#34495e"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M25.533 24.212c0 .59-.48 1.071-1.07 1.071h-6.817a1.07 1.07 0 01-1.07-1.071v-6.814c0-.591.478-1.07 1.07-1.07h6.816c.591 0 1.07.479 1.07 1.07v6.814z"
                            fill="#68c274"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M36.478 24.212c0 .59-.48 1.071-1.07 1.071h-6.816a1.07 1.07 0 01-1.07-1.071v-6.814c0-.591.48-1.07 1.07-1.07h6.816c.59 0 1.07.479 1.07 1.07v6.814z"
                            fill="#f7e846"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M47.425 24.212c0 .59-.48 1.071-1.072 1.071H39.54a1.07 1.07 0 01-1.07-1.071v-6.814c0-.591.48-1.07 1.07-1.07h6.814c.591 0 1.072.479 1.072 1.07v6.814z"
                            fill="#b5ddae"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M25.533 35.406c0 .591-.48 1.072-1.07 1.072h-6.817a1.07 1.07 0 01-1.07-1.072v-6.814c0-.59.478-1.07 1.07-1.07h6.816c.591 0 1.07.48 1.07 1.07v6.814z"
                            fill="#b7fffa"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M36.478 35.406c0 .591-.48 1.072-1.07 1.072h-6.816a1.07 1.07 0 01-1.07-1.072v-6.814c0-.59.48-1.07 1.07-1.07h6.816c.59 0 1.07.48 1.07 1.07v6.814z"
                            fill="#ffc178"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M47.425 35.406c0 .591-.48 1.072-1.072 1.072H39.54a1.07 1.07 0 01-1.07-1.072v-6.814c0-.59.48-1.07 1.07-1.07h6.814c.591 0 1.072.48 1.072 1.07v6.814z"
                            fill="#65b9cd"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M25.533 46.6c0 .592-.48 1.073-1.07 1.073h-6.817a1.07 1.07 0 01-1.07-1.072v-6.814c0-.591.478-1.07 1.07-1.07h6.816c.591 0 1.07.479 1.07 1.07v6.814z"
                            fill="#ffbafd"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M36.478 46.6c0 .592-.48 1.073-1.07 1.073h-6.816a1.07 1.07 0 01-1.07-1.072v-6.814c0-.591.48-1.07 1.07-1.07h6.816c.59 0 1.07.479 1.07 1.07v6.814z"
                            fill="#ffe6ac"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M47.425 46.6c0 .592-.48 1.073-1.072 1.073H39.54a1.07 1.07 0 01-1.07-1.072v-6.814c0-.591.48-1.07 1.07-1.07h6.814c.591 0 1.072.479 1.072 1.07v6.814z"
                            fill="#a9abff"
                            fill-rule="nonzero"
                        /></svg
                    ><span>Integrations</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure/webhooks`"
                    ><svg
                        preserveAspectRatio="xMinYMid"
                        width="32"
                        height="32"
                        viewBox="0 0 64 64"
                        xmlns="http://www.w3.org/2000/svg"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-miterlimit="1.414"
                    >
                        <path d="M32 21.897L20.333 42.103h23.334L32 21.897z" fill="#fff" />
                        <path
                            d="M32 31.23c5.15 0 9.333-4.175 9.333-9.334 0-5.15-4.183-9.333-9.333-9.333-5.156 0-9.333 4.183-9.333 9.333A9.33 9.33 0 0032 31.23zm11.667 20.207c5.15 0 9.333-4.174 9.333-9.333 0-5.15-4.183-9.333-9.333-9.333-5.157 0-9.334 4.182-9.334 9.333a9.33 9.33 0 009.334 9.333zm-23.334 0c5.15 0 9.334-4.174 9.334-9.333 0-5.15-4.183-9.333-9.334-9.333-5.156 0-9.333 4.182-9.333 9.333a9.33 9.33 0 009.333 9.333z"
                            fill="#fff"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M29.667 42.103v.001c0 5.159-4.183 9.333-9.334 9.333A9.33 9.33 0 0111 42.104c0-5.151 4.177-9.333 9.333-9.333 1.7 0 3.294.455 4.667 1.25m14-.001a9.278 9.278 0 014.667-1.249c5.15 0 9.333 4.182 9.333 9.333 0 5.159-4.183 9.333-9.333 9.333a9.33 9.33 0 01-9.334-9.333v-.001"
                            fill="none"
                            stroke="#888"
                            stroke-width="1.5"
                        />
                        <path
                            d="M27.332 29.982a9.327 9.327 0 01-4.665-8.086c0-5.15 4.177-9.333 9.333-9.333 5.15 0 9.333 4.183 9.333 9.333a9.326 9.326 0 01-4.666 8.084"
                            fill="none"
                            stroke="#888"
                            stroke-width="1.45"
                        />
                        <path
                            d="M32 26.896a5 5 0 100-10 5 5 0 000 10z"
                            fill="#f56762"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M20.333 47.104a5 5 0 10-.001-10.002 5 5 0 00.001 10.002z"
                            fill="#ffd670"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M48.667 42.104a5 5 0 10-10.002.001 5 5 0 0010.002-.001z"
                            fill="#5ac8fa"
                            fill-rule="nonzero"
                        />
                        <path
                            d="M43.667 42.104h-14"
                            fill="none"
                            stroke="#888"
                            stroke-width="1.5"
                            stroke-miterlimit="1.5"
                        />
                        <path
                            d="M37.607 31.898l-20.851.011"
                            fill="none"
                            stroke="#888"
                            stroke-width="1.63"
                            stroke-miterlimit="1.5"
                            transform="matrix(.33569 .58143 -.96247 .55568 57.087 -5.577)"
                        />
                        <path
                            d="M37.751 50.091H16.903"
                            fill="none"
                            stroke="#888"
                            stroke-width="1.63"
                            stroke-miterlimit="1.5"
                            transform="matrix(-.33569 .58143 -.96247 -.55568 81.218 47.989)"
                        /></svg
                    ><span>Webhooks</span></MenuItemComponent
                >
                <MenuItemComponent :index="`/survey/${$route.params.id}/configure/api`"
                    ><svg
                        width="32"
                        height="32"
                        viewBox="0 0 74 74"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M57.933 16.28l-36.25 36.433"
                            stroke="#888"
                            stroke-width=".766"
                            stroke-miterlimit="1.414"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M57.715 57.985L23.59 23.681"
                            stroke="#888"
                            stroke-width=".76"
                            stroke-miterlimit="1.414"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M37 26c6.075 0 11 4.925 11 11 0 6.076-4.925 11-11 11s-11-4.924-11-11c0-6.075 4.925-11 11-11z"
                            fill="#89D9FF"
                        />
                        <path d="M37 28a9 9 0 110 18 9 9 0 010-18z" fill="#fff" />
                        <path
                            d="M57.152 24.52a6.7 6.7 0 100-13.402 6.7 6.7 0 000 13.402z"
                            fill="#FF6E6C"
                        />
                        <path
                            d="M21.681 58.317a5.604 5.604 0 100-11.208 5.604 5.604 0 000 11.208z"
                            fill="#E67E22"
                        />
                        <path
                            d="M37.386 63.772a2.744 2.744 0 100-5.488 2.744 2.744 0 000 5.488z"
                            fill="#2ECC71"
                        />
                        <path
                            d="M53.41 38.802a4.3 4.3 0 014.306-4.305 4.303 4.303 0 11-4.305 4.305h-.001z"
                            fill="#FFCC01"
                        />
                        <path
                            d="M12.864 20.202a8.041 8.041 0 0113.73-5.688 8.04 8.04 0 012.355 5.688c0 4.437-3.6 8.035-8.042 8.035s-8.043-3.6-8.043-8.035z"
                            fill="#EBEDEE"
                        />
                        <path
                            d="M36.337 22.483a1.53 1.53 0 100-3.06 1.53 1.53 0 000 3.06z"
                            fill="#6DA"
                        />
                        <path
                            d="M17.642 40.265a3.265 3.265 0 116.53-.002 3.265 3.265 0 01-6.53.002z"
                            fill="#34495E"
                        />
                        <path
                            d="M49.657 56.556a6.182 6.182 0 1112.363 0 6.18 6.18 0 01-10.551 4.368 6.18 6.18 0 01-1.812-4.368z"
                            fill="#59A8FE"
                        />
                        <path
                            d="M42.717 14.65a2.491 2.491 0 100-4.982 2.491 2.491 0 000 4.982z"
                            fill="#94DBFC"
                        />
                        <path
                            d="M12.864 35.816a2.07 2.07 0 100-4.14 2.07 2.07 0 000 4.14z"
                            fill="#FE9901"
                        /></svg
                    ><span>API</span></MenuItemComponent
                >
            </MenuComponent>
        </template>
        <div class="configure-view">
            <RouterView />
        </div>
    </AppLayout>
</template>

<script>
import AppLayout from '@/layouts/AppLayout'

export default {
    name: 'Configure',

    components: {
        AppLayout
    }
}
</script>

<style scope lang="scss">
.configure-view {
    height: auto;

    max-width: 800px;
    min-height: 100vh;
    max-height: 100vh;

    margin: 0 auto;
    padding: 0 20px;
}
</style>
